import React, { useEffect, useState } from "react";
import Theme from "../Theme/color";
import { foodMenu } from "../data/data";
import { Link } from "react-scroll";
import emailjs from "emailjs-com";

const url = "https://momosgarage.petpooja.com/orders/menu";

const HomePage = () => {
  const [activeSection, setActiveSection] = useState("");
  const [active, setActive] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    message: "",
  });

  const [formDataFr, setFormDataFr] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChangeFr = (e) => {
    setFormDataFr({
      ...formDataFr,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e, fr) => {
    e.preventDefault();
    setActive(true);
    emailjs
      .send(
        "service_yaas8bo",
        "template_1vo2049",
        fr ? formDataFr : formData,
        "agMwFH3deQlOPhNE5"
      )
      .then(
        (response) => {
          setActive(false);
          console.log("SUCCESS!", response.status, response.text);
          // Optionally, clear the form
          const autoReplyParams = {
            to_email: fr ? formDataFr.email : formData.email,
            to_name: fr ? formDataFr.name : formData.name,
            message: fr ? formDataFr.message : formData.message,
          };
          emailjs
            .send(
              "service_yaas8bo",
              "template_ebupuxt",
              autoReplyParams,
              "agMwFH3deQlOPhNE5"
            )
            .then(
              (response) => {
                console.log("Auto-reply sent!", response.status, response.text);
              },
              (err) => {
                console.log("Auto-reply failed...", err);
              }
            );
          fr
            ? setFormDataFr({
                name: "",
                email: "",
                message: "",
              })
            : setFormData({
                name: "",
                email: "",
                mobile: "",
                message: "",
              });
        },
        (err) => {
          setActive(false);
          console.log("FAILED...", err);
        }
      );
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div>
      <div className="navbar navbar-expand-lg bg-light navbar-light">
        <div className="container-fluid">
          <a href="" className="navbar-brand">
            <img style={{ height: 120, width: 90 }} src="momos-logo.png" />
          </a>
          <button
            type="button"
            className="navbar-toggler"
            data-toggle="collapse"
            data-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="collapse navbar-collapse justify-content-between"
            id="navbarCollapse"
          >
            <div className="navbar-nav ml-auto">
              <Link
                className={
                  activeSection === "home"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
                activeClass="active"
                to="home"
                spy={true}
                smooth={true}
                offset={50}
                duration={500}
                onSetActive={activeSection}
              >
                Home
              </Link>

              <Link
                activeClass="active"
                to="frenchies"
                spy={true}
                smooth={true}
                offset={-120}
                duration={1000}
                className={
                  activeSection === "frenchies"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Franchises
              </Link>

              {/* <Link
                activeClass="active"
                to="menu"
                offset={-150}
                duration={500}
                href="https://momosgarage.petpooja.com/orders/menu"
                className={
                  activeSection === "menu"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                <a>Menu</a>
              </Link> */}
              {/* <Link
                activeClass="active"
                to="order-online"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                className={
                  activeSection === "order-online"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Order Online
              </Link> */}

              <Link
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-150}
                duration={500}
                className={
                  activeSection === "about"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                About Us
              </Link>
              <Link
                activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={60}
                duration={500}
                className={
                  activeSection === "contact"
                    ? "nav-item nav-link active"
                    : "nav-item nav-link"
                }
              >
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="carousel" id="home">
        <div className="">
          <div
            className="carousel-item"
            style={{ backgroundColor: Theme.backgroundYellow }}
          >
            {/* <div className="carousel-img"> */}
            {/* <img src={coverImage} alt="Image" /> */}
            {/* </div> */}
            <div className="carousel-text">
              <h1>
                <span>Discover the Ultimate Experience of MOMOS GARAGE</span>
              </h1>
              <p style={{ textTransform: "capitalize" }}>
                <span style={{ color: "#e84d3b" }}>Momos Garage</span> Provide
                96 Varieties of Momos, Including Nutritious{" "}
                <span style={{ color: "#e84d3b" }}>Wheat Momos</span> with
                authentic flavors .
              </p>
              <div className="carousel-btn">
                <a href={url} className="btn custom-btn" target="_blank">
                  View Menu
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="booking" id="frenchies">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="booking-content">
                <div className="section-header">
                  <h2>
                    Get A Franchise of{" "}
                    <span style={{ color: "#e84d3b" }}>Momos Garage </span>
                  </h2>
                </div>
                <div className="about-text">
                  <div className="section-header"></div>
                  <p>
                    Join the success of Momos Garage by becoming a franchise
                    partner. With 96 momo varieties, including nutritious wheat
                    options, we offer a proven business model and an exceptional
                    culinary experience.
                  </p>

                  <p>
                    As a franchisee, you'll receive comprehensive training,
                    ongoing support, and benefit from our strong brand presence.
                    Whether you're experienced or new to the food industry, our
                    team will guide you every step of the way.
                  </p>
                  <p>
                    Invest in a Momos Garage franchise and bring the taste of
                    authentic momos to your area. Start your journey with us
                    today!
                  </p>
                  <p>
                    For franchise queries you can call us on{" "}
                    <a href="tel:01169653929">01169653929</a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="booking-form">
                <form onSubmit={(e) => handleSubmit(e, true)}>
                  <div className="control-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        required="required"
                        id="name"
                        name="name"
                        value={formDataFr.name}
                        onChange={handleChangeFr}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="far fa-user"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email"
                        required="required"
                        id="email"
                        name="email"
                        value={formDataFr.email}
                        onChange={handleChangeFr}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="far fa-envelope"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Mobile"
                        required="required"
                        id="message"
                        name="message"
                        value={formDataFr.message}
                        onChange={handleChangeFr}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="fa fa-mobile-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn custom-btn"
                      type="submit"
                      disabled={active ? true : false}
                    >
                      {active ? "Loading..." : "Book Now"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="about">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img">
                <img src="img/momospiz.jpg" alt="Image" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-content">
                <div className="section-header">
                  <p>About Us</p>
                  <h2>Cooking Since 2023</h2>
                </div>
                <div className="about-text">
                  <p>
                    Founded by classmates Lovepreet Behal and Sukhdeep Singh,
                    Momos Garage is a Hoshiarpur-based company that began its
                    journey on 20th October 2023. Their vision was to provide an
                    extraordinary dining experience that would leave customers
                    exclaiming, "Wow!" Growing up in Hoshiarpur instills a deep
                    love for food, and as fellow food enthusiasts, Lovepreet and
                    Sukhdeep understand this passion well.
                  </p>
                  <p>
                    With professional backgrounds in the IT software industry
                    since 2014—Lovepreet as a Project Manager of Software
                    Development and Sukhdeep as a Tech Lead—they decided to
                    venture into the food industry.
                  </p>
                  <p>
                    Their mutual love for Tibetan cuisine, particularly momos,
                    inspired them to create Momos Garage. Offering an impressive
                    array of 96 varieties of momos, including Cheese Corn,
                    Mushroom, Vegetable, Paneer Tandoori masala, and simple
                    Paneer,Chicken, they also cater to health-conscious
                    customers with wheat momos in the same flavors. Momos Garage
                    serves its momos in various styles: fried, in gravy, creamy,
                    steamed, and pan-fried, ensuring there's a delicious option
                    for every palate.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="videoModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src=""
                  id="video"
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="feature">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="section-header">
                <p>Why Choose Us</p>
                <h2>Our Key Features</h2>
              </div>
              <div className="feature-text">
                <div className="feature-img">
                  <img src="img/momos.png" />
                </div>
                <p style={{ lineHeight: 1.8 }}>
                  We pride ourselves on delivering an exceptional dining
                  experience through our diverse and innovative offerings. Our
                  menu features 96 unique momo varieties, including
                  health-conscious wheat options, all meticulously crafted with
                  the finest ingredients to ensure premium quality and taste. We
                  cater to every palate with our range of cooking styles, from
                  fried and pan-fried to steamed, creamy, and in gravy.
                </p>
                <p style={{ lineHeight: 2 }}>
                  Our customer-centric approach ensures that every visit is
                  memorable, and our background in the IT software industry
                  allows us to bring a unique blend of expertise and innovation
                  to the food business, ensuring efficiency and continuous
                  improvement. Choose Momos Garage for a culinary adventure that
                  embodies variety, quality, and a passion for excellence.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="row">
                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-cooking"></i> */}
                    <h3>Authentic Flavors</h3>
                    <p>
                      Our momos are prepared using traditional recipes and the
                      finest ingredients, ensuring an authentic taste in every
                      bite.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-vegetable"></i> */}
                    <h3>Exceptional Variety</h3>
                    <p>
                      we offer an unparalleled selection of 96 different momo
                      varieties, including unique flavors like cheese corn,
                      mushroom, vegetable, paneer masala, and simple paneer.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-medal"></i> */}
                    <h3>Best quality Momos</h3>
                    <p>
                      We prioritize quality and hygiene to provide you with a
                      dining experience that is not only delicious but also safe
                      and clean.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-meat"></i> */}
                    <h3>Customer-Centric Approach</h3>
                    <p>
                      At Momos Garage, customer satisfaction is our top
                      priority. We strive to create an exceptional dining
                      experience that leaves you feeling valued and delighted
                      with every visit.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-courier"></i> */}
                    <h3>Passion for Momos</h3>
                    <p>
                      Founded by food enthusiasts with a deep love for Tibetan
                      cuisine, our commitment to quality is unwavering. Each
                      momo is crafted with the finest ingredients and utmost
                      care to ensure a memorable dining experience.
                    </p>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-fruits-and-vegetables"></i> */}
                    <h3>Health-Conscious Options</h3>
                    <p>
                      Understanding the importance of health and wellness, we
                      serve{" "}
                      <span style={{ fontWeight: "bold", color: "#e84d3b" }}>
                        Wheat Momos
                      </span>{" "}
                      for all our varieties, providing a healthier alternative
                      without compromising on taste.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-fruits-and-vegetables"></i> */}
                    <h3>Diverse Cooking Styles</h3>
                    <p>
                      Our momos are prepared in various styles to cater to
                      different taste preferences. Whether you prefer them
                      fried, in gravy, creamy, steamed, or pan-fried, we have
                      something to delight every palate.
                    </p>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="feature-item">
                    {/* <i className="flaticon-fruits-and-vegetables"></i> */}
                    <h3>Nutritional Benefits</h3>
                    <p>
                      Our wheat momos are not only a healthier option but also
                      packed with essential nutrients. Wheat flour offers a good
                      source of fiber, vitamins, and minerals, making our momos
                      a nutritious choice for those seeking to maintain a
                      balanced diet.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="booking" id="contact">
        <div className="container">
          <div className="row align-items-center" style={{ paddingBottom: 50 }}>
            <div className="col-lg-12">
              <div className="booking-content">
                <div className="section-header">
                  <h2 style={{ textAlign: "center" }}>Contact Us</h2>
                </div>
                <div className="about-text">
                  <div className="section-header"></div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="booking-form">
                <form onSubmit={handleSubmit}>
                  <div className="control-group">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Name"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required="required"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="far fa-user"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="input-group">
                      <input
                        type="email"
                        id="email"
                        name="email"
                        className="form-control"
                        placeholder="Email"
                        required="required"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="far fa-envelope"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="control-group">
                    <div className="input-group">
                      <input
                        type="text"
                        id="mobile"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Mobile"
                        required="required"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="fa fa-mobile-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="control-group">
                    <div className="input-group">
                      <textarea
                        type="text"
                        id="message"
                        name="message"
                        className="form-control"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required="required"
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <i className="fa fa-message"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    <button
                      className="btn custom-btn"
                      type="submit"
                      disabled={active ? true : false}
                    >
                      {active ? "Loading..." : "Send"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Footer Start --> */}
      <div className="footer">
        <h2 style={{ textAlign: "center", marginBottom: 30, color: "#fbaf32" }}>
          Our Outlets
        </h2>
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="footer-contact">
                <h2>Mukerian</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>
                  {"  "}Momos Garage, RC Tower, near Domins Pizza, Talwara Rd,
                  near Domino, Mukerian, Punjab 144211
                </p>
              </div>
            </div>

            <div
              className="col-lg-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="footer-contact">
                <h2>Talwara</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>
                  Momos Garage, Sundar Vihar, AK Farm & Swimming Pool, 52 Gate
                  road, Talwara, Bhera, Punjab 144216
                </p>
              </div>
            </div>
            <div
              className="col-lg-4"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="footer-contact">
                <h2>Dasuya</h2>
                <p>
                  <i className="fa fa-map-marker-alt"></i>
                  Momos Garage, Pathankot - Jalandhar Rd, near Guru Gobind Singh
                  Filling Station, Swastik Vihar, Dasuya, Punjab 144205
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <h2
            style={{
              textAlign: "center",
              color: "#fbaf32",
              fontSize: 14,
            }}
          >
            Online Delivery Partners
          </h2>
          <div
            className="row align-items-center"
            style={{ justifyContent: "center", paddingBottom: 20 }}
          >
            <img
              src="img/zomato1.png"
              style={{ height: 50, width: 50, borderRadius: 25 }}
            />
            <img
              src="img/swiggy.png"
              style={{ height: 50, width: 50, borderRadius: 25 }}
            />
          </div>
        </div>
        <div
          style={{
            textAlign: "center",
          }}
        >
          Call US:<a href="tel:01169653929"> 01169653929</a>
        </div>
        <div
          style={{
            textAlign: "center",
            paddingBottom: 20,
          }}
        >
          Email: <a href="mailto:hello@momosgarage.in"> hello@momosgarage.in</a>
        </div>
        <div className="copyright">
          <div className="container">
            <p>
              Copyright &copy; <a href="#">Aabrestro Garage LLP</a>, All Right
              Reserved 2024
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="footer-social">
              <div className="row align-items-center">
                <span
                  style={{ fontSize: 14, paddingRight: 10, color: "#fbaf32" }}
                >
                  Checkout on Instagram
                </span>
                <a
                  href="https://www.instagram.com/momosgarage.in/"
                  target="_blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer End --> */}
    </div>
  );
};

export default HomePage;
