import React, { Fragment } from 'react'
import HomePage from '../Pages/HomePage';

const Layout = () => {
  return (
    <Fragment>
        <HomePage />
    </Fragment>
  )
}

export default Layout;